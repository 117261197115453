import './App.scss'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import config from './config/config'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { FirebaseContext } from './providers/Firebase/firebase'
import { Marker } from '@react-google-maps/api'
import Timeline from './components/Timeline'
import Logo from './static/logo.svg'
import moment from 'moment'
import { getLocation } from './utils/geoip'

const libraries = ['visualization,geometry,drawing,places']

export default function App() {
  const defaultProps = {
    zoom: 18,
  }
  const containerStyle = {
    height: 'calc(100vh - 7rem - 5px)',
  }
  let params = new URLSearchParams(window.location.search)
  let deliveryTaskId = params.get('id')
  const { app, api } = useContext(FirebaseContext)
  const [mapLoaded, setMapLoaded] = useState(false)
  const [mapCenter, setMapCenter] = useState(null)
  const onLoadMap = (map) => {
    const updateMapCenter = () => {
      setMapCenter(map.center)
    }
    const initMaps = () => {
      if (!mapLoaded) {
        setMapLoaded(true)
        window.google.maps.event.addListener(
          map,
          'center_changed',
          updateMapCenter
        )
      }
    }
    //this._map = map;
    window.google.maps.event.addListenerOnce(map, 'bounds_changed', initMaps)
  }
  const reducer = (state, action) => {
    const { type, position, deliveryTask } = action
    switch (type) {
      case 'update':
        return { position, deliveryTask }
      case 'init':
        return { deliveryTask }
    }
  }
  const deliveryTaskReducer = (state, action) => {
    const { type, deliveryTask } = action
    switch (type) {
      case 'set':
        return deliveryTask
    }
  }
  const [positionObj, dispatch] = useReducer(reducer, null)
  const [deliveryTask, dispatchDeliveryTask] = useReducer(deliveryTaskReducer, null)

  useEffect(async () => {
    api.getUserStatus(dispatch)
    if (deliveryTaskId) {
      await api.getDeliveryTask(deliveryTaskId, dispatchDeliveryTask)
    }
  }, [])

  useEffect(async () => {
    if (deliveryTask && deliveryTask.deliveryCoordinates) {
      setMapCenter(deliveryTask.deliveryCoordinates)
    } else {
      const location = await getLocation()
      setMapCenter(location)
    }
  }, [deliveryTask])

  return (
    <div className="wrapper">
      <header className="title">
        <a href="https://armadaops.com" className="header-logo">
          <img src={Logo} className="logo" />
          <span>ArmadaOps</span>
        </a>
      </header>
      <aside>
        <Timeline deliveryTaskId={deliveryTaskId} />
      </aside>
      <main>
        <LoadScript
          googleMapsApiKey={config.googleMaps.apiKey}
          libraries={libraries}
        >
          <GoogleMap
            center={mapCenter}
            zoom={defaultProps.zoom}
            mapContainerStyle={containerStyle}
            onLoad={onLoadMap}
            options={{
              mapTypeControl: false,
              streetViewControl: false,
              styles: [
                {
                  featureType: 'poi',
                  stylers: [{ visibility: 'off' }],
                },
                {
                  featureType: 'transit',
                  elementType: 'labels.icon',
                  stylers: [{ visibility: 'off' }],
                },
              ],
            }}
          >
            {positionObj && positionObj.position && mapLoaded ? (
              <Marker
                title={'Courier'}
                icon={{
                  url:
                    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAMTAAADEwE9ZoPHAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAABA1JREFUSImtll1MW2UYx//nHMrhcBiFtggNX8INJS7BxZLxkYyEkuhV+QhTEc0G42PEARcaww3IFi4ICSHDaJhOV9QAChS5ptKJC9K14MeYdPhBGSas0ELpqX1pG1ov+AhByiDwuzo5ed/fP8+T85z3pXAEHMclZWZmVqenpytlMpkkNDQ0CgC8Xq9jdXV1zWw2m4xG46eEkKWjPP9DLpcnFxUVfdPb27vicrkCwXC5XAGNRmMtLCwckEgkiYe5qIMvcnJyKkpLSz+sr69P9nq96LnXB+PvT7G84cW/Xj8AgGdpyMWhyExPQl1lOUQiEbq7uy2Dg4OtU1NTvUEDCgoKWpuamhpUKlX0R599iW/Hf4M18hUwXOShlW6RDcQ6Z/CGKgM3qt7B2NjYent7++3x8fGbu2uY3Yfs7OzK5ubmlry8vOja91uh/TMcbmkGaBEbtJW0KAzuiBQ8/MOGaf13aKit4FJTUy9YLJZnFovll70AuVyeXF1drSkvL4+rea8VDzbTQfEvBBUfhAoTY3HzHGZ/0KK+tiLMbre/bDKZhgkhTgYAVCrV3c7OzpyPP/8a2r/CTyTfX81TRwC0/QluXK+KMhgMcWazWUtzHJdUUlKS5/P5MKifBSITTizfQ5yI/rEZeDweFBcXF3Acl8jk5uZ+0NbW9lrPvT7cdyQd2fPjINDRoK2/4u2yy7xerxfotLS0CzzPw/B4MejXchKY8CgYZhfA8zwUCoWSlkqlMgCwOn2nlu/ybMclk8lkNM/zYr/fD9fOEJ0FLo8ffr8fHMeJab/fH6AoChQCZxYABEBR2zNME0KcFEUhgmWes+n4nGNDQFEU3G63g7bb7TYAiI0UnVlArHjbZbfb7bTZbJ4RBAEXX3oRW2Tj1PIttwNZ51PgcrkwNzf3kDYajXdGRkZW6irLESfMnDog1jmNmitvYnh4eGV6evoLmhCyNDQ0pGcYBq/nZ4Bynuzs2A/lXMJbryoREhICrVb7PSFkiQEAm81mYFm2pPHd61E/3x+FhUSAFoWdSO4nG7gUuYBbTQ3o7OxcGBgYKCOECAwAEEKcm5ubKwkJCfn1NVe5uR9H8M9GAAH2eJNNOZdwKXIBn3S0QKfTrff09DTNz88/APadB4uLi4+Wl5fD4uPjMxrrrnGwPcHfj37a/rcEqWbL7UDs2iSu5afgVlMDdDrdWkdHx+2JiYnuvfCDm7Kysq6UlpbebGxsTPb5fLij6YfhsQXLDg/c3u1h5FkKcjGLi+dTUHu1DAzDoKuryzI6OtoyOTn51XNLlkgkCWq1ul+j0VgFQQh66AuCENBoNFa1Wt0nlUrjD23fUUEcxyUqlcoqhUKRGRMTI2FZNhoAPB7P+s61xWgyme4edW35D2Gj2hQ77g1WAAAAAElFTkSuQmCC',
                  anchor: new window.google.maps.Point(16, 10.5),
                }}
                position={{ lat: positionObj.position.lat * 1, lng: positionObj.position.lng * 1 }}
              />
            ) : null}
            {deliveryTask && deliveryTask.deliveryCoordinates && mapLoaded ? (
              <Marker
                title={'Delivery location'}
                icon={{
                  url:
                      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAF5AAABeQGfAOmRAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAxVJREFUSIntl19ok1cUwH/nfkljbZfqrE7RVRF8GMqYD9NJFIT1QRgtIsY9OJzatQbmGAiCsKl5keGDOpgaKtYXRYaKIqiIf9hoU13Ff8haKBalwSquUltr067Jd48vpg2lrUlKfPL3eM75zo8L9557PyGNmtryEsc7sEZglbomIA5TFFtEDgim36jptpJsULjsJiadPbLlWs9wHgiHMf+VBbYp8gswJZUs8pUwtWg6RpyspFZduuOdvB7oSQ93C7pnRqxxfziMleCpoFPa++wMwmqAedMWvS7/fF3xwtlf4fMW5rLYIQaTA7Q+u0t9y/l4S8fNAhX1CJzrLJ4VlFDd8h0i/GYwyQ3Ld7JkQblnQrYx6Oh6pL9f+rm3L9HtR9hhxFADsOqLDZ58SQFmfzxftldG/IKASsigzAWYW/pZvpxDzPDPwT95GqBlBjAAIpJ3cZrHmPdiG4UP4g/ivIotgKp9L8K3HmuAxwBtzx/kXfr05SNexbswmCdGkEMA15r/1BsPL+ZN2tpxL7nvwo99iqJi9zvfLI7d6ispK0N18YNYlFttV+Mej9f7UeFUfN7JOU80VUtP/AX32+s53rC360rzicKkHfQh1H3SHt051DVUF1iHyD6BOamYMY4WF5S4Io6qWlVVIDX21Fo7vC/EGFW1Vq2KVSuDbtznqju0eRWeiLAtsil6Gt4+BFIETwWd6b0dXyNOWNFlOS11BILcdMXuntXeeD0cxg7HR+GnE0v9yUHvDWDhBL2t+FgWWR99OTIx6jn+47umV45rKoDOCUi7rJiK0aRjigEOVtc/FmUN8H8O0oSorq3dVP9wrIJxJ9fhqmhURTYCmo1VlB8OVzX+NV7NO5+Pt8/H/v2y8tMCRFZkIlXYE6mKHnhXXUazOrK58VfgZAbSMzNj0V2Z9MzskhC0XzxVwD/jVN2xif7v04/M+C2zoKZ2ZanjTTYB80ek2hNuYunR6qbnmfbK6lo8suXvFxapANJ/EXpxpTIbadZigNrNDS2KfAskAVeE9ZHqhvxfbSlCxwJbQ8cCW3P9/g2JIylN4kuv9gAAAABJRU5ErkJggg==',
                  anchor: new window.google.maps.Point(15, 30),
                  labelOrigin: new window.google.maps.Point(16, 10)
                }}
                position={{ lat: deliveryTask.deliveryCoordinates.lat * 1, lng: deliveryTask.deliveryCoordinates.lng * 1 }}
                label={{
                  color: 'black',
                  text: moment(deliveryTask.deliveryTime.seconds * 1000).format('HH:mm'),
                  fontSize: '8px',
                }}
              />
            ) : null}
          </GoogleMap>
        </LoadScript>
      </main>
      <footer>
        <a href="https://armadaops.com">© {moment().year()} ArmadaOps</a>
      </footer>
    </div>
  )
}
