import React, { createContext, useEffect, useState } from 'react'
import config from '../../config/config'
import locales from '../../config/locales'
import firebase from 'firebase'
import 'firebase/database'
import { defineMessages } from 'react-intl'
import IntlProvider from 'react-intl/lib/src/components/provider'
import { Status } from '../../components/Timeline'

// we create a React Context, for this to be accessible
// from a component later
const FirebaseContext = createContext(null)
export { FirebaseContext }

const dispatchStore = {
  courierDispatch: () => {},
}

export default ({ children }) => {
  const [courierId, setCourierId] = useState(null)

  if (!firebase.apps.length) {
    firebase.initializeApp(config.firebase.prod.initConfig)
  }

  const firebaseConfig = {
    app: firebase,
    database: firebase.database(),
    firestore: firebase.firestore(),

    api: {
      getUserStatus,
      getTimeline,
      getDeliveryTask,
    },
  }

  const dispatchActions = (deliveryTask, dispatch) => {
    const actions = []
    if (deliveryTask.actions) {
      for (let key in deliveryTask.actions) {
        if (deliveryTask.actions.hasOwnProperty(key)) {
          actions[key * 1 - 1] = deliveryTask.actions[key]
        }
      }
    }
    dispatch({ type: 'update', actions: actions})
  }

  const dispatchDeliveryTask = (deliveryTask, dispatch) => {
    dispatch({ type: 'set', deliveryTask})
  }

  const getUserId = async (email) => {
    const users = await firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get()

    let userId = null
    if (users.size === 1) {
      users.forEach((doc) => {
        userId = doc.data().uuid
      })
    }
    return userId
  }

  const checkDeliveryTask = async (deliveryTask) => {
    // const snapshot = await firebaseConfig.firestore.collection("deliveryTasks").doc(deliveryTaskId).get();
    // const deliveryTask = snapshot.data()
    if (deliveryTask.status === Status.STARTED) {
      const accessSnapshot = await firebaseConfig.database
        .ref('userStatusAccess/' + firebase.auth().currentUser.uid)
        .get()
      const access = accessSnapshot.val()
      if (!access) {
        const snapshot = await firebaseConfig.firestore
          .collection('couriers')
          .doc(deliveryTask.courierId)
          .get()
        const courier = snapshot.data()
        const userId = await getUserId(courier.email)
        const currentUser = await firebase.auth().signInAnonymously()
        if (currentUser) {
          firebase
            .database()
            .ref('userStatusAccess/' + currentUser.user.uid + '/' + userId)
            .set({
              timestamp: firebase.database.ServerValue.TIMESTAMP,
            })
            .then(async () => {
              setCourierId(userId)
            })
        }
      }
    }
  }

  function getUserStatus(dispatch) {
    dispatchStore.courierDispatch = dispatch
  }

  async function getTimeline(deliveryTaskId, dispatch) {
    const currentUser = await firebase.auth().signInAnonymously()
    const docTask = await firebaseConfig.firestore
      .collection('deliveryTasks')
      .doc(deliveryTaskId)
      .get()
    const deliveryTask = docTask.data()
    await checkDeliveryTask(deliveryTask)
    dispatchActions(deliveryTask, dispatch)
  }

  async function getDeliveryTask(deliveryTaskId, dispatch) {
    const currentUser = await firebase.auth().signInAnonymously()
    const docTask = await firebaseConfig.firestore
      .collection('deliveryTasks')
      .doc(deliveryTaskId)
      .get()
    const deliveryTask = docTask.data()
    await checkDeliveryTask(deliveryTask)
    dispatchDeliveryTask(deliveryTask, dispatch)
  }

  const getLocaleMessages = async (l, ls) => {
    if (ls) {
      for (let i = 0; i < ls.length; i++) {
        if (ls[i]['locale'] === l) {
          const { default: messages } = await defineMessages(ls[i].messages)

          return messages
        }
      }
    }

    return {}
  }

  const locale = config.locale.defaultLocale
  const [messages, setMessages] = useState([])
  useEffect(() => {
    const loadMessages = async () => {
      const messages = await getLocaleMessages(locale, locales)
      setMessages(messages)
    }
    loadMessages()
  }, [locale, locales])

  useEffect(() => {
    if (courierId) {
      firebaseConfig.database
        .ref('userStatus/' + courierId)
        .on('value', (snapshot) => {
          const vals = snapshot.val()
          let position = { lat: vals.lat, lng: vals.lng }
          dispatchStore.courierDispatch({ type: 'update', position: position })
        })
    }
  }, [courierId])

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <FirebaseContext.Provider value={firebaseConfig}>
        {children}
      </FirebaseContext.Provider>
    </IntlProvider>
  )
}
