import React, { useContext, useEffect, useReducer } from 'react'
import { FirebaseContext } from '../providers/Firebase/firebase'
import './Timeline.scss'
import moment from 'moment'
import { useIntl } from 'react-intl'

export const Status = Object.freeze({
  UNASSIGNED: 'UNASSIGNED',
  ASSIGNED: 'ASSIGNED',
  STARTED: 'STARTED',
  CANCELLED: 'CANCELLED',
  FINISHED: 'FINISHED',
})

const Timeline = ({ deliveryTaskId }) => {
  const { app, api } = useContext(FirebaseContext)
  const intl = useIntl()

  const cleanActions = (actions) => {
    const newActions = actions.sort((a, b) => {
      return b.timestamp.seconds - a.timestamp.seconds
    })
    const actionsCleaned = []
    let prevStatus = null
    for (let i = 0; i < newActions.length; i++) {
      if (newActions[i].status !== prevStatus) {
        actionsCleaned.push(newActions[i])
      }
      prevStatus = newActions[i].status
    }
    return actionsCleaned
  }
  const reducer = (state, action) => {
    const { type, actions } = action
    switch (type) {
      case 'update':
        return cleanActions(actions)
      case 'init':
        return []
    }
  }
  const [actions, dispatch] = useReducer(reducer, [])
  const formatTimestamp = (timestamp) => {
    const momentTimestamp = moment(timestamp.seconds * 1000)
    if (Math.abs(moment().diff(momentTimestamp, 'days')) > 1) {
      return momentTimestamp.format('DD MMM, HH:mm')
    } else {
      return momentTimestamp.format('HH:mm')
    }
  }
  const formatStatus = (status, isDescription) => {
    switch (status) {
      case Status.UNASSIGNED:
        return intl.formatMessage({
          id: isDescription ? 'status_desc_unassigned' : 'status_unassigned',
        })
      case Status.ASSIGNED:
        return intl.formatMessage({
          id: isDescription ? 'status_desc_assigned' : 'status_assigned',
        })
      case Status.STARTED:
        return intl.formatMessage({
          id: isDescription ? 'status_desc_started' : 'status_started',
        })
      case Status.CANCELLED:
        return intl.formatMessage({
          id: isDescription ? 'status_desc_cancelled' : 'status_cancelled',
        })
      case Status.FINISHED:
        return intl.formatMessage({
          id: isDescription ? 'status_desc_finished' : 'status_finished',
        })
    }
  }

  useEffect(() => {
    if (deliveryTaskId) {
      api.getTimeline(deliveryTaskId, dispatch)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="timeline label">
        <ul>
          {actions.map((action, index) => {
            return (
              <li key={index}>
                <div className="bullet green full" />
                <div className="time label">
                  {formatTimestamp(action.timestamp)}
                </div>
                <div className="desc sub-label">
                  <h3>{formatStatus(action.status)}</h3>
                  <h4>{formatStatus(action.status, true)}</h4>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default Timeline
