const axios = require('axios').default

export const getLocation = () => {
  return new Promise((resolve) => {
    axios.get('http://ip-api.com/json/', {
      crossDomain: true
    })
      .then(data => {
        resolve({lat: data.data.lat, lng: data.data.lon})
      });
  })
}