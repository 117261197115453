const config = {
    firebase: {
        prod: {
            initConfig: {
                apiKey: 'AIzaSyDS-4J-fiyGi-_g61uofyJU662a4_mxroE',
                authDomain: 'armadaops-com.firebaseapp.com',
                databaseURL: 'https://armadaops-com.firebaseio.com',
                projectId: 'armadaops-com',
                storageBucket: 'armadaops-com.appspot.com',
                messagingSenderId: '715023510847',
                appId: '1:715023510847:web:721c6e9f825839850c778a',
            },
            messaging: {
                publicVapidKey:
                    'BNMDPxKbsd2HdjL85WFRy-a-LKQ9C_tfE9aLbJkm3rKsC_U0lNOnQ0DIecqDBxlZ76q_N7I6O1jp4p6ny8Yas_0',
            },
        },
        dev: {
            initConfig: {
                apiKey: 'AIzaSyDS-4J-fiyGi-_g61uofyJU662a4_mxroE',
                authDomain: 'armadaops-com.firebaseapp.com',
                databaseURL: 'https://armadaops-com.firebaseio.com',
                projectId: 'armadaops-com',
                storageBucket: 'armadaops-com.appspot.com',
                messagingSenderId: '715023510847',
                appId: '1:715023510847:web:721c6e9f825839850c778a',
            },
            messaging: {
                publicVapidKey:
                    'BNMDPxKbsd2HdjL85WFRy-a-LKQ9C_tfE9aLbJkm3rKsC_U0lNOnQ0DIecqDBxlZ76q_N7I6O1jp4p6ny8Yas_0',
            },
        },

    },
    googleMaps: {
        apiKey: 'AIzaSyDS-4J-fiyGi-_g61uofyJU662a4_mxroE',
    },
    locale: {
        defaultLocale: 'en',

    }
}

export default config
